import React from 'react';
import { Accordion, Segment, Icon, Button } from 'semantic-ui-react';
import { Row, Col } from 'react-bootstrap';
import * as edt from '../../constants/EventDisplayTypes';
import * as c from '../../constants/Colors';

function formatClock(clock) {
  const minutes = Math.floor(clock / 60);
  const seconds = Math.floor(clock % 60);
  return String(minutes).padStart(2, 0) + ':' + String(seconds).padStart(2, 0);
}

function formatClockDiff(clockDiff) {
  let lengthString = '';
  if (clockDiff > 1) {
    const minutes = Math.floor(clockDiff / 60);
    const seconds = Math.floor(clockDiff % 60);
    lengthString = (minutes ? minutes + 'm' : '') + (seconds ? seconds + 's' : '0s');
  }
  return lengthString;
}

function formatRosterLine(roster, rosterLookup) {
  if (!Array.isArray(roster)) {
    return [];
  }
  return roster.map((id) => rosterLookup[id].labelNoComma).sort((a, b) => String(a).localeCompare(b)).join(', ');
}

class GameStatsPlayByPlayTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    const { quarters } = props;
    const activeIndicesForQuarterIndex = [];
    for (let i = 0; i < quarters.length; i++) {
      activeIndicesForQuarterIndex.push([]);
    }
    this.state = {
      activeIndicesForQuarterIndex,
    };
  }

  onClickTitle = (quarterIndex, index) => {
    const activeIndicesForQuarterIndex = Object.assign([], this.state.activeIndicesForQuarterIndex);
    const activeIndices = Object.assign([], activeIndicesForQuarterIndex[quarterIndex]);
    const i = activeIndices.indexOf(index);
    if (i !== -1) {
      activeIndices.splice(i, 1);
    } else { activeIndices.push(index); }
    activeIndicesForQuarterIndex[quarterIndex] = activeIndices;
    this.setState({ activeIndicesForQuarterIndex });
  };

  expandAll = () => {
    const activeIndicesForQuarterIndex = [];
    const { quarters } = this.props;
    for (let i = 0; i < quarters.length; i++) {
      activeIndicesForQuarterIndex.push([]);
      const { points } = quarters[i];
      for (let j = 0; j < points.length; j++) {
        activeIndicesForQuarterIndex[i].push(j);
      }
    }
    this.setState({ activeIndicesForQuarterIndex });
  };

  collapseAll = () => {
    const activeIndicesForQuarterIndex = [];
    const { quarters } = this.props;
    for (let i = 0; i < quarters.length; i++) { activeIndicesForQuarterIndex.push([]); }
    this.setState({ activeIndicesForQuarterIndex });
  };

  renderPointTitle = (point) => {
    const { rosterLookup } = this.props;
    const { weScored, theyScored, ourScore, theirScore, clock, clockDiff, isOLine, rosterLine } = point;
    const style = {};
    let className = 'play-by-play-point';
    if (weScored) {
      className += ' we-scored';
    } else if (theyScored) {
      className += ' they-scored';
    } else { className += ' no-one-scored'; }
    return (
      <Accordion.Title style={style} className={className} >
        <Icon name="dropdown" />
        <span className="play-by-play-score">{ourScore}-{theirScore}</span>
        <span className="play-by-play-is-o-line">{isOLine ? 'O-Line' : 'D-Line'}</span>
        <span className="play-by-play-clock">{formatClock(clock)}</span>
        <span className="play-by-play-length">{formatClockDiff(clockDiff)}</span>
        <span className="play-by-play-roster">{formatRosterLine(rosterLine, rosterLookup)}</span>
      </Accordion.Title>
    );
  };

  renderPointContent = (point) => {
    const { rosterLookup } = this.props;
    return (
      <Accordion.Content>
        {point.events.map((event, index) => (
          <RenderEvent event={event} rosterLookup={rosterLookup} key={index} />
        ))}
      </Accordion.Content>
    );
  };

  render() {
    const { activeIndicesForQuarterIndex } = this.state;
    const { quarters } = this.props;
    return (
      <Row className="play-by-play">
        <Col>
          <Button color="green" onClick={this.expandAll}>Expand all</Button>
          <Button color="red" onClick={this.collapseAll}>Collapse all</Button>
        </Col>
        <Col>
          {quarters.map((quarter, quarterIndex) => {
            if (!quarter.points || !quarter.points.length) { return null; }
            const activeIndices = activeIndicesForQuarterIndex[quarterIndex];
            return (
              <div key={quarterIndex} className="play-by-play-quarter" style={{ marginTop: 'calc(1rem - 0.142857em)' }}>
                <h3 className="play-by-play-quarter-header">{quarter.label}</h3>
                <Accordion
                  activeIndex={activeIndices}
                  onTitleClick={(e, data) => this.onClickTitle(quarterIndex, data.index)}
                  styled
                  panels={quarter.points.map((point, index) => {
                    return { key: index, title: this.renderPointTitle(point), content: this.renderPointContent(point) };
                  })}
                  exclusive={false}
                  fluid
                />
              </div>
            );
          })}
        </Col>
      </Row>
    );
  }
}

class RenderEvent extends React.PureComponent {
  render() {
    const { event, rosterLookup } = this.props;
    const { type, rosterIdThrower, rosterIdReceiver, receiverX, receiverY, throwerX, throwerY, lastRosterLine, rosterLine } = event;
    let iconName;
    let iconColor = 'black';
    let iconComponent;
    let distance;
    let eventLabel = edt.DEFINITION[type].label;
    if ([edt.PULL_INBOUNDS, edt.PULL_OUT_OF_BOUNDS, edt.PULL_OUR_OFFSIDES, edt.PULL_THEIR_OFFSIDES].includes(type)) {
      eventLabel += ' by ' + (rosterIdReceiver && rosterLookup[rosterIdReceiver] ? rosterLookup[rosterIdReceiver].labelNoComma : 'Unknown');
      iconName = 'share';
      if (type === edt.PULL_OUR_OFFSIDES) {
        iconColor = c.COLOR_ICON_BAD;
        distance = 40;
      } else if (type === edt.PULL_THEIR_OFFSIDES) {
        iconColor = c.COLOR_ICON_GOOD;
        distance = 90;
      } else {
        distance = receiverY - 20;
        if (type === edt.PULL_OUT_OF_BOUNDS) { iconColor = c.COLOR_ICON_BAD; }
      }
    } else if ([edt.PASS, edt.DROPPED_PASS, edt.THROWAWAY, edt.CALLAHAN_THROWN, edt.SCORE].includes(type)) {
      const color = [edt.PASS, edt.SCORE].includes(type) ? '#000' : '#db2828';
      iconComponent = <RenderPassDirection receiverX={receiverX} receiverY={receiverY} throwerX={throwerX} throwerY={throwerY} color={color} />;
      const distanceX = receiverX - throwerX;
      const distanceY = receiverY - throwerY;
      distance = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));
      if (type === edt.PASS) {
        if (distance < 5) {
          eventLabel = 'Dish';
        } else if (Math.abs(distanceX) > 2 * Math.abs(distanceY)) {
          if (distance < 10) {
            eventLabel = distanceY < 0 ? 'Dump' : 'Dish';
          } else { eventLabel = 'Swing'; }
        } else if (distanceY < 0) {
          eventLabel = 'Dump';
        } else if (distance > 40) {
          eventLabel = 'Huck';
        }
      } else if (type === edt.SCORE) {
        iconName = 'plus circle';
      } else if (type === edt.DROPPED_PASS) {
        if (distance > 40) {
          eventLabel = 'Dropped huck';
        } else { eventLabel = 'Dropped pass'; }
      } else if (type === edt.THROWAWAY) {
        if (distance > 40) {
          eventLabel = 'Huck throwaway';
        }
      }
      if (rosterIdThrower) { eventLabel += ' from ' + (rosterLookup[rosterIdThrower] ? rosterLookup[rosterIdThrower].labelNoComma : 'Unknown'); }
      if (rosterIdReceiver) { eventLabel += ' to ' + (rosterLookup[rosterIdReceiver] ? rosterLookup[rosterIdReceiver].labelNoComma : 'Unknown'); }
    } else if (type === edt.STALL) {
      iconName = 'dont';
      if (rosterIdThrower) { eventLabel += ' by ' + (rosterLookup[rosterIdThrower] ? rosterLookup[rosterIdThrower].labelNoComma : 'Unknown'); }
    } else if (type === edt.THEIR_GOAL) {
      iconName = 'minus circle';
      iconColor = c.COLOR_ICON_BAD;
    } else if (type === edt.BLOCK) {
      iconName = 'shield';
      iconColor = c.COLOR_ICON_GOOD;
      eventLabel += ' by ' + (rosterIdReceiver && rosterLookup[rosterIdReceiver] ? rosterLookup[rosterIdReceiver].labelNoComma : 'Unknown');
    } else if (type === edt.CALLAHAN) {
      iconName = 'plus circle';
      eventLabel += ' by ' + (rosterIdReceiver && rosterLookup[rosterIdReceiver] ? rosterLookup[rosterIdReceiver].labelNoComma : 'Unknown');
    } else if ([edt.THROWAWAY_CAUSED, edt.STALL_CAUSED].includes(type)) {
      iconName = 'x';
    } else if ([edt.TIMEOUT, edt.INJURY, edt.UNKNOWN_SUB].includes(type)) {
      iconName = 'clock';
      const oldLine = {};
      const newLine = [];
      const stayed = [];
      for (const rosterId of lastRosterLine) { oldLine[rosterId] = true; }
      for (const rosterId of rosterLine) {
        if (oldLine[rosterId]) {
          delete oldLine[rosterId];
          stayed.push(rosterId);
        } else { newLine.push(rosterId); }
      }
      if (stayed.length !== 7) {
        if (stayed.length < 4) {
          if (stayed.length) {
            eventLabel += '; ' + formatRosterLine(stayed, rosterLookup) + ' stayed';
          } else { eventLabel += '; Wholesale'; }
        } else { eventLabel += '; ' + formatRosterLine(Object.keys(oldLine), rosterLookup) + ' came off'; }
        if (newLine.length) { eventLabel += '; ' + formatRosterLine(newLine, rosterLookup) + ' came on'; }
      } else { eventLabel += '; No substitutions'; }
    }
    let distanceString;
    if (distance >= 1) { distanceString = String(Math.floor(distance)) + 'y'; }
    return (
      <Segment className="play-by-play-event">
        <div className="play-by-play-event-icon">
          {iconComponent ? iconComponent : <SvgIcon name={iconName} color={iconColor} />}
        </div>
        <div className="play-by-play-event-distance">
          {distanceString}
        </div>
        <div className="play-by-play-event-label">
          {eventLabel}
        </div>
      </Segment>
    );
  }
}

class RenderPassDirection extends React.PureComponent {
  render() {
    const { throwerX, throwerY, receiverX, receiverY, color } = this.props;
    const angle = Math.atan2(receiverY - throwerY, receiverX - throwerX);
    const x1 = -Math.cos(angle);
    const y1 = Math.sin(angle);
    const x2 = Math.cos(angle);
    const y2 = -Math.sin(angle);
    const xAngle = angle + (Math.PI / 2);
    const point1x = (Math.cos(angle) / 2) + (Math.cos(xAngle) / 2);
    const point1y = -(Math.sin(angle) / 2) - (Math.sin(xAngle) / 2);
    const point2x = (Math.cos(angle) / 2) - (Math.cos(xAngle) / 2);
    const point2y = -(Math.sin(angle) / 2) + (Math.sin(xAngle) / 2);
    const points = x2 + ',' + y2 + ' ' + point1x + ',' + point1y + ' ' + point2x + ',' + point2y;
    return (
      <svg height="2em" width="2em" viewBox="-1.5 -1.5 3 3">
        <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={color} strokeWidth=".25" />
        <polygon points={points} fill={color} stroke={color} strokeWidth=".25" />
      </svg>
    );
  }
}

const SvgIcon = ({ name, color }) => {
  switch (name) {
    case 'share':
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color} transform="scale(-1, 1)"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z" /></svg>;
    case 'plus circle':
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" /></svg>;
    case 'dont':
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z" /></svg>;
    case 'minus circle':
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" /></svg>;
    case 'shield':
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color}><g><rect fill="none" height="24" width="24" /></g><g><path d="M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M18,11.09c0,4-2.55,7.7-6,8.83 c-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25l6,2.25V11.09z" /></g></svg>;
    case 'x':
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>;
    case 'clock':
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" /></svg>;
    case 'question':
    default:
      return <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill={color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" /></svg>;
  }
};

export default GameStatsPlayByPlayTable;
