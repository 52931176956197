import React from 'react';
import * as sd from '../../constants/SvgDimensions';
import * as edt from '../../constants/EventDisplayTypes';

class GameStatsDrawEvents extends React.PureComponent {
  render() {
    const { fieldMapWidth } = this.props;
    return (
      <g>
        {Object.keys(edt.DEFINITION).map((type, index) => {
          const { displayOnMap, label, thrower, receiver, receiverPos, throwerPos, throwerColor, receiverColor, lineColor } = edt.DEFINITION[type];
          if (!displayOnMap) { return null; }
          const x = (sd.SVG_INDENT_X * 2) + fieldMapWidth + sd.SVG_LEGEND_INDENT_X;
          const y = sd.SVG_INDENT_Y + sd.SVG_LEGEND_Y + sd.SVG_LEGEND_Y_GAP * index;
          return (
            <g key={type}>
              {receiverPos && throwerPos ? <line x1={x - sd.SVG_LEGEND_R / 2} x2={x + sd.SVG_LEGEND_LINE_LENGTH + sd.SVG_LEGEND_R / 2} y1={y} y2={y} stroke={lineColor} strokeWidth={sd.SVG_LEGEND_LINE_WIDTH} /> : null}
              {thrower ? <rect x={x - sd.SVG_LEGEND_R} y={y - sd.SVG_LEGEND_R} height={sd.SVG_LEGEND_R * 2} width={sd.SVG_LEGEND_R * 2} fill={throwerColor} /> : null}
              {receiver ? <circle cx={x + sd.SVG_LEGEND_LINE_LENGTH} cy={y} r={sd.SVG_LEGEND_R} fill={receiverColor} /> : null}
              <text x={x + sd.SVG_LEGEND_X_GAP + sd.SVG_LEGEND_LINE_LENGTH} y={y} alignmentBaseline="central">{label}</text>
            </g>
          );
        })}
      </g>
    );
  }
}

export default GameStatsDrawEvents;
