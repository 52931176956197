export const QUARTER_1 = 1;
export const QUARTER_2 = 2;
export const QUARTER_3 = 3;
export const QUARTER_4 = 4;
export const OT_1 = 5;
export const OT_2 = 6;

export const DEFINITION = {
  [QUARTER_1]: { label: 'First quarter', abbrev: 'Q1' },
  [QUARTER_2]: { label: 'Second quarter', abbrev: 'Q2' },
  [QUARTER_3]: { label: 'Third quarter', abbrev: 'Q3' },
  [QUARTER_4]: { label: 'Fourth quarter', abbrev: 'Q4' },
  [OT_1]: { label: 'Overtime 1', abbrev: 'OT1' },
  [OT_2]: { label: 'Overtime 2', abbrev: 'OT2' },
};
