import React from 'react';
import { Row } from 'react-bootstrap';

class GameStatsQuarterBreakdown extends React.PureComponent {
  render() {
    const { gameInfo } = this.props;
    const periods = [
      { abbrev: 'Q1', home: 0, away: 0 },
      { abbrev: 'Q2', home: 0, away: 0 },
      { abbrev: 'Q3', home: 0, away: 0 },
      { abbrev: 'Q4', home: 0, away: 0 },
      { abbrev: 'OT1', home: 0, away: 0 },
      { abbrev: 'OT2', home: 0, away: 0 },
    ];
    for (const { times, key } of [
      { times: gameInfo.scoreTimesAway, key: 'away' },
      { times: gameInfo.scoreTimesHome, key: 'home' },
    ]) {
      if (Array.isArray(times)) {
        for (const time of times) {
          if (time !== 0) {
            if (time <= 720) {
              periods[0][key]++;
            } else if (time <= 1440) {
              periods[1][key]++;
            } else if (time <= 2160) {
              periods[2][key]++;
            } else if (time <= 2880) {
              periods[3][key]++;
            } else if (time <= 3180) {
              periods[4][key]++;
            } else {
              periods[5][key]++;
            }
          }
        }
      }
    }
    if ((gameInfo.statusID < 51 || gameInfo.statusID > 60) && !periods[5].home && !periods[5].away) {
      periods.pop();
      if ((gameInfo.statusID < 41 || gameInfo.statusID > 60) && !periods[4].home && !periods[4].away) {
        periods.pop();
      }
    }
    return (
      <div className="quarter-breakdown">
        <Row>
          <table>
            <thead>
              <tr>
                <td></td>
                {periods.map(({ abbrev }) => (
                  <td key={abbrev}>{abbrev}</td>
                ))}
                <td>T</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{gameInfo.teamAway}</td>
                {periods.map(({ abbrev, away }) => (
                  <td key={abbrev}>{away}</td>
                ))}
                <td>{gameInfo.scoreAway}</td>
              </tr>
              <tr>
                <td>{gameInfo.teamHome}</td>
                {periods.map(({ abbrev, home }) => (
                  <td key={abbrev}>{home}</td>
                ))}
                <td>{gameInfo.scoreHome}</td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
    );
  }
}

export default GameStatsQuarterBreakdown;
