export const COLOR_PASS = 'lightblue';
export const COLOR_GOAL = 'green';
export const COLOR_THROWAWAY = 'red';
export const COLOR_STALL = 'purple';
export const COLOR_CALLAHAN_THROWN = 'darkred';
export const COLOR_FIELD = 'white';
export const COLOR_POSITIVE = '#A6D6BA';
export const COLOR_NEUTRAL = '#B9C2D6';
export const COLOR_NEGATIVE = '#D69F9D';
export const COLOR_ICON_GOOD = '#008000';
export const COLOR_ICON_BAD = '#DB2828';
