import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { FaSync } from 'react-icons/fa';

class GameStatsInfoHeader extends React.PureComponent {
  componentWillUnmount() {
    if (this.autoRefreshButtonTimeout) {
      clearTimeout(this.autoRefreshButtonTimeout);
    }
  }

  render() {
    const { showEither, gameInfo, filterTeams, toggleTeamFilter, refreshing, autoRefresh, toggleAutoRefresh, loadMoment, load } = this.props;
    const buttons = [
      { value: gameInfo.tsgIdAway, name: gameInfo.teamAbbrevAway },
      { value: gameInfo.tsgIdHome, name: gameInfo.teamAbbrevHome },
    ];
    if (showEither) { buttons.unshift({ value: 0, name: 'Both' }); }
    return (
      <Row>
        <Col md={4} >
          <div className="info-header">
            Filter by team
          </div>
          <Button.Group>
            {buttons.map(({ value, name }) => (
              <Button
                key={value}
                primary
                active={filterTeams === value}
                onClick={(e) => toggleTeamFilter(value)}
              >
                {name}
              </Button>
            ))}
          </Button.Group>
        </Col>
        <Col md={4} >
          <div className="info-header">
            Refresh stats
          </div>
          <Button
            primary
            onClick={load}
            disabled={refreshing}
          >
            {refreshing ? <Spinner size="sm" animation="border" role="status" /> : <FaSync />}
          </Button>
          <div
            style={{ display: 'inline-block', marginLeft: '10px', height: '37px' }}
            onTouchStart={() => { this.autoRefreshButtonTimeout = setTimeout(toggleAutoRefresh, 1500); }}
            onTouchEnd={() => { clearTimeout(this.autoRefreshButtonTimeout); }}
            onMouseDown={() => { this.autoRefreshButtonTimeout = setTimeout(toggleAutoRefresh, 1500); }}
            onMouseUp={() => { clearTimeout(this.autoRefreshButtonTimeout); }}
            onMouseLeave={() => { clearTimeout(this.autoRefreshButtonTimeout); }}
          >
            {(autoRefresh ? 'Auto-refreshed on' : 'Accurate as of') + ' ' + loadMoment.format('h:mm a')}
          </div>
        </Col>
      </Row>
    );
  }
}

export default GameStatsInfoHeader;
