export const O_POINT = 1;
export const D_POINT = 2;
export const O_TO = 3;
export const D_TO = 4;

export const DEFINITION = {
  [O_POINT]: { label: 'O points' },
  [D_POINT]: { label: 'D points' },
  [O_TO]: { label: 'O out of TO' },
  [D_TO]: { label: 'D out of TO' },
};
