import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Row, Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import * as s from '../../constants/Styles';

const GameStatsStatsTable = ({ filteredRosterStatsTable: data }) => {
  return (
    <Row style={{ marginTop: '10px' }}>
      <Col>
        <ReactTable
          data={data}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          defaultSortDesc={true}
          resizable={false}
          filterable={false}
          defaultPageSize={500}
          minRows={0}
          showPagination={false}
          className="roster-stats-table -striped -highlight"
          columns={[
            {
              Header: 'Player',
              columns: [
                {
                  Header: <div style={{ textAlign: 'left' }}>Name</div>,
                  accessor: 'name',
                  minWidth: 100,
                  defaultSortDesc: false,
                  Footer: 'Total',
                },
                {
                  Header: <div>Team</div>,
                  accessor: 'team',
                  minWidth: 50,
                  defaultSortDesc: false,
                  style: { textAlign: 'center' },
                  Footer: data.reduce((output, { team }) => {
                    if (!output) { return team; }
                    if (output !== team) { return 'Both'; }
                    return output;
                  }, ''),
                },
                {
                  Header: <div>#</div>,
                  accessor: 'jerseyNumber',
                  minWidth: 40,
                  defaultSortDesc: false,
                  style: { textAlign: 'center' },
                },
              ],
            },
            {
              Header: 'Stats',
              columns: [
                {
                  Header: () => <Popup content="Points played" trigger={<div>PP</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'pointsPlayed',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.pointsPlayed, 0),
                },
                {
                  Header: () => <Popup content="Offensive Points played" trigger={<div>OPP</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'oPointsPlayed',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.oPointsPlayed, 0),
                },
                {
                  Header: () => <Popup content="Defensive Points played" trigger={<div>DPP</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'dPointsPlayed',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.dPointsPlayed, 0),
                },
                {
                  Header: () => <Popup content="Assists" trigger={<div>AST</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'assists',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.assists, 0),
                },
                {
                  Header: () => <Popup content="Goals" trigger={<div>GLS</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'goals',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.goals, 0),
                },
                {
                  Header: () => <Popup content="Blocks" trigger={<div>BLK</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'blocks',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.blocks, 0),
                },
                {
                  Header: () => <Popup content="Plus / Minus" trigger={<div>+/-</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'plusMinus',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.plusMinus, 0),
                },
                {
                  Header: () => <Popup content="Receiving Yards" trigger={<div>Rcv Yds</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'yardsReceived',
                  minWidth: 60,
                  Footer: data.reduce((total, row) => total + row.yardsReceived, 0),
                },
                {
                  Header: () => <Popup content="Throwing Yards" trigger={<div>Thr Yds</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'yardsThrown',
                  minWidth: 60,
                  Footer: data.reduce((total, row) => total + row.yardsThrown, 0),
                },
                {
                  Header: () => <Popup content="Total Yards" trigger={<div>Yds</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'yardsTotal',
                  minWidth: 40,
                  Footer: data.reduce((total, row) => total + row.yardsTotal, 0),
                },
                {
                  Header: () => <Popup content="Completions" trigger={<div>Cmp</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'completions',
                  minWidth: 50,
                  Footer: data.reduce((total, row) => total + row.completions, 0),
                },
                {
                  Header: () => <Popup content="Completion percentage" trigger={<div>Cmp%</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'completionPercentage',
                  minWidth: 60,
                  Footer: Math.floor(100 * data.reduce((total, row) => total + row.completions, 0) / Math.max(data.reduce((total, row) => total + row.throwAttempts, 0), 1)),
                },
                {
                  Header: () => <Popup content="Hockey Assists" trigger={<div>HA</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'hockeyAssists',
                  minWidth: 30,
                  Footer: data.reduce((total, row) => total + row.hockeyAssists, 0),
                },
                {
                  Header: () => <Popup content="Throwaways" trigger={<div>T</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'throwaways',
                  minWidth: 30,
                  Footer: data.reduce((total, row) => total + row.throwaways, 0),
                },
                {
                  Header: () => <Popup content="Times stalled out" trigger={<div>S</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'stalls',
                  minWidth: 30,
                  Footer: data.reduce((total, row) => total + row.stalls, 0),
                },
                {
                  Header: () => <Popup content={<div className="content">Callahans caught<br />(also scored as goal and block)</div>} trigger={<div>C</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'callahans',
                  minWidth: 30,
                  Footer: data.reduce((total, row) => total + row.callahans, 0),
                },
                {
                  Header: () => <Popup content="Drops" trigger={<div>D</div>} inverted position="top right" style={s.POP_UP_STYLE} hideOnScroll />,
                  accessor: 'drops',
                  minWidth: 30,
                  Footer: data.reduce((total, row) => total + row.drops, 0),
                },
              ],
            },
          ]}
          defaultSorted={[
            {
              id: 'plusMinus',
              desc: true,
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default GameStatsStatsTable;
