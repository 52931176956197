import React from 'react';
import { Button } from 'react-bootstrap';
import { Form, Checkbox } from 'semantic-ui-react';

class GameStatsFieldMapFilter extends React.PureComponent {
  render() {
    const { header, options, values, onClick, sortByValue } = this.props;
    return (
      <Form.Group grouped>
        <div className="info-header">
          {header}
        </div>
        {options.sort((a, b) => ((b.value === -1) - (a.value === -1) || (b.value === -2) - (a.value === -2) || (sortByValue ? a.value - b.value : String(a.label).localeCompare(b.label)))).map((option) => {
          if (option.value >= 0) {
            return <Form.Field
              key={option.value}
              label={option.label + (option.count ? ' (' + option.count + ')' : ' ')}
              control={Checkbox}
              value={option.value}
              checked={values.includes(option.value)}
              onChange={onClick}
            />;
          } else {
            return <Form.Field
              key={option.value}
              control={Button}
              className={'btn-filter ' + (option.value === -1 ? 'select-all' : 'deselect-all')}
              onClick={() => onClick(null, { value: option.value })}
            >
              {option.label + (option.count ? ' (' + option.count + ')' : ' ')}
            </Form.Field>;
          }
        })}
      </Form.Group>
    );
  }
}

export default GameStatsFieldMapFilter;
