import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container, Loader } from 'semantic-ui-react';
import moment from 'moment';
import urlRequest from '../../utils/urlRequest';
import * as t from '../../constants/EventTypes';
import * as edt from '../../constants/EventDisplayTypes';
import * as eft from '../../constants/EventFilterTypes';
import * as lft from '../../constants/LineFilterTypes';
import * as pft from '../../constants/PeriodFilterTypes';
import InfoHeader from './InfoHeader';
import FieldMapFilter from './FieldMapFilter';
import FieldMapEvents from './FieldMapEvents';
import StatsTable from './StatsTable';
import PlayByPlayTable from './PlayByPlayTable';
import TeamStatsTable from './TeamStatsTable';
import QuarterBreakdown from './QuarterBreakdown';
import ScoresGraph from './ScoresGraph';

const SHOW_TEAM_STATS = true;
const SHOW_TABLE = true;
const SHOW_FIELD_MAP = true;
const SHOW_PLAY_BY_PLAY = true;
const SHOW_GRAPH = false;

const URL = process.env.REACT_APP_EXPRESS_URL + 'game/';
const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION;

const HEAT_MAP_DIVISIONS = 51;

function createFullFilter(definition) {
  const filters = [];
  for (const value of Object.keys(definition)) {
    filters.push(Number(value));
  }
  return filters;
}

function incrementRosterStats(stat, amount, rosterStats = {}) {
  rosterStats[stat] += amount;
}

function processPass(event, rosterStatsTableLookup, newY, prevY, prevThrower) {
  const distance = newY !== undefined && prevY !== undefined ? newY - prevY : 0;
  const isCompleted = [t.POSSESSION, t.GOAL].includes(event.t);
  const rosterStats = rosterStatsTableLookup[event.r];
  const prevRosterStats = rosterStatsTableLookup[prevThrower];
  incrementRosterStats('throwAttempts', 1, prevRosterStats);
  if (isCompleted) {
    incrementRosterStats('catches', 1, rosterStats);
    incrementRosterStats('yardsReceived', distance, rosterStats);
    incrementRosterStats('completions', 1, prevRosterStats);
    incrementRosterStats('yardsThrown', distance, prevRosterStats);
  }
  if (event.y - prevY > 40) {
    incrementRosterStats('huckAttempts', 1, prevRosterStats);
    if (isCompleted) { incrementRosterStats('huckCompletions', 1, prevRosterStats); }
  }
}

function saveEvent(type, { teamSeasonId, period, line, receiver, receiverX, receiverY, thrower, throwerX, throwerY, unknownPos, currentRosterLine, newRosterLine }, eventsTable, point, possession) {
  const event = { type, teamSeasonId, period, line, id: eventsTable.length + 1 };
  const eventDef = edt.DEFINITION[type];
  if (eventDef.thrower) { event.rosterIdThrower = thrower; }
  if (eventDef.receiver) { event.rosterIdReceiver = receiver; }
  if (!unknownPos) {
    if (eventDef.receiverPos) {
      event.receiverX = receiverX;
      event.receiverY = receiverY;
    }
    if (eventDef.throwerPos) {
      event.throwerX = throwerX;
      event.throwerY = throwerY;
    }
  }
  if (Array.isArray(currentRosterLine) && Array.isArray(newRosterLine)) {
    event.lastRosterLine = currentRosterLine;
    event.rosterLine = newRosterLine;
  }
  if (eventDef.displayOnMap) {
    eventsTable.push(event);
    possession.events.push(event);
  }
  point.events.push(event);
}

function newQuarter(label, startClock, quarter, info) {
  if (quarter && quarter.points && quarter.points.length && info && info.quarters) {
    info.quarters.push(quarter);
  }
  return { label, startClock, points: [] };
}

function newPoint(isOLine, ourScore, theirScore, clock, quarter, point, info) {
  if (point && point.events && point.events.length) {
    if ([edt.SCORE, edt.CALLAHAN].includes(point.events[point.events.length - 1].type)) {
      point.weScored = true;
    } else if ([edt.THEIR_GOAL, edt.CALLAHAN_THROWN].includes(point.events[point.events.length - 1].type)) {
      point.theyScored = true;
    }
    point.ourScore = ourScore;
    point.theirScore = theirScore;
    const oldClock = quarter.points.length ? quarter.points[quarter.points.length - 1].clock : quarter.startClock;
    point.clock = clock ? clock : 0;
    point.clockDiff = oldClock > clock ? oldClock - clock : 1;
    if (quarter && quarter.points) {
      quarter.points.push(point);
      if (info && info.rosterStatsTableLookup) {
        for (const rosterId of Object.keys(point.uniqueRosters)) {
          incrementRosterStats(point.isOLine ? 'oPointsPlayed' : 'dPointsPlayed', 1, info.rosterStatsTableLookup[rosterId]);
        }
      }
    }
  }
  return { isOLine, events: [], uniqueRosters: {} };
}

function newPossession(point, didScore, possession, info) {
  if (info && possession && possession.events && possession.events.length) {
    const { possessions } = info;
    if (didScore) { possession.didScore = true; }
    if (point) { possession.point = point; }
    possessions.push(possession);
  }
  return { events: [], didScore: false, wasRedzone: false };
}

const formatData = (data) => {
  const { game, rostersHome, rostersAway, tsgHome, tsgAway } = data;
  if ((!tsgHome || String(tsgHome.source).substring(0, 2) !== 'sk') && (!tsgAway || String(tsgAway.source).substring(0, 2) !== 'sk')) {
    return { skip: true };
  }
  const gameInfo = {};
  if (game) {
    gameInfo.statusID = game.status_id;
    gameInfo.scoreAway = game.score_away;
    gameInfo.scoreHome = game.score_home;
    gameInfo.scoreTimesAway = game.score_times_away;
    gameInfo.scoreTimesHome = game.score_times_home;
    if (game.type_id === 5) {
      gameInfo.teamAbbrevHome = 'Home';
      gameInfo.teamAbbrevAway = 'Away';
      gameInfo.teamHome = 'Home';
      gameInfo.teamAway = 'Away';
    } else {
      gameInfo.teamAbbrevHome = game.team_season_home.abbrev;
      gameInfo.teamAbbrevAway = game.team_season_away.abbrev;
      gameInfo.teamHome = game.team_season_home.city + ' ' + game.team_season_home.team.name;
      gameInfo.teamAway = game.team_season_away.city + ' ' + game.team_season_away.team.name;
    }
  }
  const unknownRoster = { id: -1, jerseyNumber: -1, label: 'Unknown', labelNoComma: 'Unknown', fullName: 'Unknown', firstName: 'Unknown' };
  const rosterLookup = {
    '0': unknownRoster,
    '-1': unknownRoster,
    '-2': unknownRoster,
    '-3': unknownRoster,
    '-4': unknownRoster,
    '-5': unknownRoster,
    '-6': unknownRoster,
    '-7': unknownRoster,
  };
  for (const rosters of [rostersHome, rostersAway]) {
    const uniqueNames = {};
    if (!Array.isArray(rosters)) { continue; }
    for (const roster of rosters) {
      const { id, player, jersey_number } = roster;
      const { first_name, last_name } = player;
      const derivedRoster = { id, jerseyNumber: jersey_number, label: last_name, labelNoComma: last_name, fullName: first_name + ' ' + last_name, firstName: first_name };
      const lastNameKey = last_name.toUpperCase();
      if (!uniqueNames[lastNameKey]) {
        uniqueNames[lastNameKey] = [];
      }
      uniqueNames[lastNameKey].push(derivedRoster);
      rosterLookup[id] = derivedRoster;
    }
    for (const lastNameKey in uniqueNames) {
      const derivedRosters = uniqueNames[lastNameKey];
      if (derivedRosters.length > 1) {
        let useFirstInitial = true;
        const firstInitials = {};
        for (const derivedRosterIndex in derivedRosters) {
          const derivedRoster = derivedRosters[derivedRosterIndex];
          const firstInitial = derivedRoster.firstName.substring(0, 1).toUpperCase();
          if (firstInitials[firstInitial]) {
            useFirstInitial = false;
            break;
          }
          firstInitials[firstInitial] = true;
        }
        for (const derivedRosterIndex in derivedRosters) {
          const derivedRoster = derivedRosters[derivedRosterIndex];
          if (useFirstInitial) {
            derivedRoster.label = derivedRoster.label + ', ' + derivedRoster.firstName.substring(0, 1);
            derivedRoster.labelNoComma = derivedRoster.firstName.substring(0, 1) + ' ' + derivedRoster.labelNoComma;
          } else {
            derivedRoster.label = derivedRoster.label + ', ' + derivedRoster.firstName;
            derivedRoster.labelNoComma = derivedRoster.firstName + ' ' + derivedRoster.labelNoComma;
          }
        }
      }
    }
  }
  const rosterStatsTable = [];
  const rosterStatsTableLookup = {};
  const eventsTableHome = [];
  const eventsTableAway = [];
  const quartersHome = [];
  const quartersAway = [];
  const possessionsHome = [];
  const possessionsAway = [];
  const newPointInfo = { rosterStatsTableLookup };
  for (const tsg of [tsgHome, tsgAway]) {
    if (!tsg) { continue; }
    const { id, rosterIds, events, teamSeasonId, startOnOffense } = tsg;
    if (!Array.isArray(rosterIds)) { continue; }
    const isHome = tsg === tsgHome;
    gameInfo[isHome ? 'tsgIdHome' : 'tsgIdAway'] = id;
    const eventsTable = isHome ? eventsTableHome : eventsTableAway;
    const quarters = isHome ? quartersHome : quartersAway;
    const possessions = isHome ? possessionsHome : possessionsAway;
    const rosterStatsUnknown = {
      name: 'Unknown',
      tsgId: id,
      teamSeasonId,
      jerseyNumber: 'N/A',
      team: gameInfo[isHome ? 'teamAbbrevHome' : 'teamAbbrevAway'],
      isHome,
      pointsPlayed: 0,
      oPointsPlayed: 0,
      dPointsPlayed: 0,
      assists: 0,
      goals: 0,
      blocks: 0,
      plusMinus: 0,
      yardsReceived: 0,
      yardsThrown: 0,
      completions: 0,
      throwAttempts: 0,
      huckCompletions: 0,
      huckAttempts: 0,
      hockeyAssists: 0,
      throwaways: 0,
      stalls: 0,
      callahans: 0,
      callahansThrown: 0,
      drops: 0,
      catches: 0,
    };
    for (const rosterId of rosterIds) {
      const roster = rosterLookup[rosterId];
      if (!roster) { continue; }
      const rosterStats = Object.assign({}, rosterStatsUnknown);
      rosterStats.jerseyNumber = roster.jerseyNumber;
      rosterStats.name = roster.label;
      rosterStatsTable.push(rosterStats);
      rosterStatsTableLookup[rosterId] = rosterStats;
    }
    rosterStatsTableLookup[-1] = rosterStatsUnknown;
    rosterStatsTableLookup[-2] = rosterStatsUnknown;
    rosterStatsTableLookup[-3] = rosterStatsUnknown;
    rosterStatsTableLookup[-4] = rosterStatsUnknown;
    rosterStatsTableLookup[-5] = rosterStatsUnknown;
    rosterStatsTableLookup[-6] = rosterStatsUnknown;
    rosterStatsTableLookup[-7] = rosterStatsUnknown;
    rosterStatsTable.push(rosterStatsUnknown);
    let prevThrower;
    let prevX;
    let prevY;
    let prevPrevThrower;
    let currentLineType;
    let currentRosterLine;
    let currentPeriod = pft.QUARTER_1;
    let ourScore = 0;
    let theirScore = 0;
    const newQuarterInfo = { quarters };
    const newPossessionInfo = { possessions };
    let quarter = newQuarter('First Quarter', 720);
    let point = newPoint(startOnOffense);
    let possession = newPossession();
    let isOffenseInSecondOT;
    let lastEventType;

    const eventsParsed = JSON.parse(events);
    if (Array.isArray(eventsParsed)) {
      for (const event of eventsParsed) {
        const rosterStats = rosterStatsTableLookup[event.r] ? rosterStatsTableLookup[event.r] : {};
        const prevRosterStats = rosterStatsTableLookup[prevThrower] ? rosterStatsTableLookup[prevThrower] : {};
        const prevPrevRosterStats = rosterStatsTableLookup[prevPrevThrower] ? rosterStatsTableLookup[prevPrevThrower] : {};
        const eventInfo = {
          teamSeasonId,
          period: currentPeriod,
          line: currentLineType,
          currentRosterLine,
          thrower: prevThrower,
          throwerX: prevX ? prevX : 0,
          throwerY: prevY || prevY === 0 ? prevY : 40,
          receiver: event.r,
          receiverX: event.x ? event.x : 0,
          receiverY: event.y || event.y === 0 ? event.y : 40,
          unknownPos: (!event.x && event.x !== 0) || (!event.y && event.y !== 0),
          rawEventData: event,
        };
        // Non-offensive events
        if ([t.SET_D_LINE, t.SET_D_LINE_NO_PULL, t.PULL_INBOUNDS, t.PULL_OUT_OF_BOUNDS, t.PULL_OUR_OFFSIDES, t.PULL_THEIR_OFFSIDES, t.BLOCK, t.THROWAWAY_CAUSED, t.CALLAHAN, t.SCORED_ON, t.STALL_CAUSED, t.D_PENALTY_ON_US, t.O_PENALTY_ON_THEM, t.THEIR_MIDPOINT_TIMEOUT, t.THEIR_TIMEOUT_ON_O, t.OUR_TIMEOUT_ON_D, t.SET_O_LINE, t.SET_O_LINE_NO_PULL, t.OUR_MIDPOINT_TIMEOUT, t.THEIR_TIMEOUT_ON_D, t.OUR_TIMEOUT_ON_O, t.UNKNOWN, t.INJURY_ON_O, t.INJURY_ON_D, t.END_OF_Q1, t.HALFTIME, t.END_OF_Q3, t.GAME_OVER, t.END_OF_OT1, t.END_OF_OT2].includes(event.t)) {
          prevThrower = null;
          prevX = null;
          prevY = null;
          prevPrevThrower = null;
          if ([t.SET_D_LINE, t.SET_D_LINE_NO_PULL].includes(event.t) && Array.isArray(event.l)) {
            if (!point.rosterLine) {
              point.rosterLine = event.l;
            } else {
              let eventDisplayType = edt.UNKNOWN_SUB;
              if ([t.OUR_MIDPOINT_TIMEOUT, t.THEIR_MIDPOINT_TIMEOUT].includes(lastEventType)) {
                eventDisplayType = edt.TIMEOUT;
              } else if (lastEventType === t.INJURY_ON_D) { eventDisplayType = edt.INJURY; }
              saveEvent(eventDisplayType, { ...eventInfo, newRosterLine: event.l }, eventsTable, point, possession);
            }
            for (const r of event.l) {
              point.uniqueRosters[r] = true;
            }
            if (event.t === t.SET_D_LINE) { currentLineType = lft.D_POINT; }
            currentRosterLine = event.l;
          } else if ([t.SET_O_LINE, t.SET_O_LINE_NO_PULL].includes(event.t) && Array.isArray(event.l)) {
            if (!point.rosterLine) {
              point.rosterLine = event.l;
            } else {
              let eventDisplayType = edt.UNKNOWN_SUB;
              if ([t.OUR_MIDPOINT_TIMEOUT, t.THEIR_MIDPOINT_TIMEOUT].includes(lastEventType)) {
                eventDisplayType = edt.TIMEOUT;
              } else if (lastEventType === t.INJURY_ON_O) { eventDisplayType = edt.INJURY; }
              saveEvent(eventDisplayType, { ...eventInfo, newRosterLine: event.l }, eventsTable, point, possession);
            }
            for (const r of event.l) {
              point.uniqueRosters[r] = true;
            }
            if (event.t === t.SET_O_LINE) {
              currentLineType = lft.O_POINT;
              possession = newPossession(point, false, possession, newPossessionInfo);
            }
            currentRosterLine = event.l;
          } else if (event.t === t.BLOCK) {
            incrementRosterStats('blocks', 1, rosterStats);
            saveEvent(edt.BLOCK, eventInfo, eventsTable, point, possession);
          } else if (event.t === t.STALL) {
            saveEvent(edt.STALL_CAUSED, eventInfo, eventsTable, point, possession);
          } else if (event.t === t.THROWAWAY_CAUSED) {
            saveEvent(edt.THROWAWAY_CAUSED, eventInfo, eventsTable, point, possession);
          } else if (event.t === t.CALLAHAN) {
            incrementRosterStats('blocks', 1, rosterStats);
            incrementRosterStats('goals', 1, rosterStats);
            incrementRosterStats('callahans', 1, rosterStats);
            saveEvent(edt.CALLAHAN, eventInfo, eventsTable, point, possession);
            point = newPoint(false, ++ourScore, theirScore, event.s, quarter, point, newPointInfo);
          } else if (event.t === t.SCORED_ON) {
            saveEvent(edt.THEIR_GOAL, eventInfo, eventsTable, point, possession);
            point = newPoint(true, ourScore, ++theirScore, event.s, quarter, point, newPointInfo);
          } else if (event.t === t.PULL_INBOUNDS) {
            saveEvent(edt.PULL_INBOUNDS, eventInfo, eventsTable, point, possession);
          } else if (event.t === t.PULL_OUT_OF_BOUNDS) {
            saveEvent(edt.PULL_OUT_OF_BOUNDS, eventInfo, eventsTable, point, possession);
          } else if (event.t === t.PULL_OUR_OFFSIDES) {
            saveEvent(edt.PULL_OUR_OFFSIDES, eventInfo, eventsTable, point, possession);
          } else if (event.t === t.PULL_THEIR_OFFSIDES) {
            saveEvent(edt.PULL_THEIR_OFFSIDES, eventInfo, eventsTable, point, possession);
          } else if (event.t === t.THEIR_MIDPOINT_TIMEOUT) {
            currentLineType = lft.D_TO;
          } else if (event.t === t.OUR_MIDPOINT_TIMEOUT) {
            currentLineType = lft.O_TO;
          } else if (event.t === t.END_OF_Q1) {
            currentPeriod = pft.QUARTER_2;
            possession = newPossession(point, false, possession, newPossessionInfo);
            point = newPoint(!startOnOffense, ourScore, theirScore, 0, quarter, point, newPointInfo);
            quarter = newQuarter('Second Quarter', 720, quarter, newQuarterInfo);
          } else if (event.t === t.HALFTIME) {
            currentPeriod = pft.QUARTER_3;
            possession = newPossession(point, false, possession, newPossessionInfo);
            point = newPoint(startOnOffense, ourScore, theirScore, 0, quarter, point, newPointInfo);
            quarter = newQuarter('Third Quarter', 720, quarter, newQuarterInfo);
          } else if (event.t === t.END_OF_Q3) {
            currentPeriod = pft.QUARTER_4;
            possession = newPossession(point, false, possession, newPossessionInfo);
            point = newPoint(!startOnOffense, ourScore, theirScore, 0, quarter, point, newPointInfo);
            quarter = newQuarter('Fourth Quarter', 720, quarter, newQuarterInfo);
          } else if (event.t === t.GAME_OVER) {
            if (ourScore === theirScore) {
              currentPeriod = pft.OT_1;
              isOffenseInSecondOT = !event.o;
              possession = newPossession(point, false, possession, newPossessionInfo);
              point = newPoint(event.o, ourScore, theirScore, 0, quarter, point, newPointInfo);
              quarter = newQuarter('First Overtime', 300, quarter, newQuarterInfo);
            }
          } else if (event.t === t.END_OF_OT1) {
            if (ourScore === theirScore) {
              currentPeriod = pft.OT_2;
              possession = newPossession(point, false, possession, newPossessionInfo);
              point = newPoint(isOffenseInSecondOT, ourScore, theirScore, 0, quarter, point, newPointInfo);
              quarter = newQuarter('Second Overtime', 0, quarter, newQuarterInfo);
            }
          }
        } else if (event.t === t.POSSESSION) { // Offensive events
          if (event.y > 80) { possession.wasRedzone = true; }
          if (!prevThrower) {
            prevThrower = event.r;
            prevX = event.x;
            prevY = event.y;
          } else if (prevThrower !== event.r) {
            processPass(event, rosterStatsTableLookup, event.y, prevY, prevThrower, currentRosterLine);
            saveEvent(edt.PASS, eventInfo, eventsTable, point, possession);
            prevPrevThrower = prevThrower;
            prevThrower = event.r;
            prevX = event.x;
            prevY = event.y;
          }
        } else if (event.t === t.STALL) {
          incrementRosterStats('stalls', 1, prevRosterStats);
          saveEvent(edt.STALL, eventInfo, eventsTable, point, possession);
          possession = newPossession(point, false, possession, newPossessionInfo);
        } else if (event.t === t.CALLAHAN_THROWN) {
          processPass(event, rosterStatsTableLookup, event.y, prevY, prevThrower, currentRosterLine);
          incrementRosterStats('throwaways', 1, prevRosterStats);
          incrementRosterStats('callahansThrown', 1, prevRosterStats);
          saveEvent(edt.CALLAHAN_THROWN, eventInfo, eventsTable, point, possession);
          possession = newPossession(point, false, possession, newPossessionInfo);
          point = newPoint(true, ourScore, ++theirScore, event.s, quarter, point, newPointInfo);
        } else if (event.t === t.DROP) {
          incrementRosterStats('drops', 1, rosterStats);
          incrementRosterStats('throwAttempts', 1, prevRosterStats);
          saveEvent(edt.DROPPED_PASS, eventInfo, eventsTable, point, possession);
          possession = newPossession(point, false, possession, newPossessionInfo);
        } else if (event.t === t.THROWAWAY) {
          processPass(event, rosterStatsTableLookup, event.y, prevY, prevThrower, currentRosterLine);
          incrementRosterStats('throwaways', 1, prevRosterStats);
          saveEvent(edt.THROWAWAY, eventInfo, eventsTable, point, possession);
          possession = newPossession(point, false, possession, newPossessionInfo);
        } else if (event.t === t.GOAL) {
          processPass(event, rosterStatsTableLookup, 100, prevY, prevThrower, currentRosterLine);
          incrementRosterStats('goals', 1, rosterStats);
          incrementRosterStats('assists', 1, prevRosterStats);
          incrementRosterStats('hockeyAssists', 1, prevPrevRosterStats);
          saveEvent(edt.SCORE, eventInfo, eventsTable, point, possession);
          possession = newPossession(point, true, possession, newPossessionInfo);
          point = newPoint(false, ++ourScore, theirScore, event.s, quarter, point, newPointInfo);
        } else if (event.t === t.O_PENALTY_ON_US) {
          prevY = prevY < 20 ? prevY / 2 : prevY - 10;
        } else if (event.t === t.D_PENALTY_ON_THEM) {
          if (event.c) {
            prevY = 100;
            prevX = 0;
          } else {
            prevY = prevY + 10 > 100 ? 100 : prevY + 10;
          }
        }
        lastEventType = event.t;
      }
    }
    newPossession(newPossessionInfo, point);
    newPoint(null, ourScore, theirScore, 0, quarter, point, newPointInfo);
    newQuarter(null, null, quarter, newQuarterInfo);
    gameInfo.finalPeriod = currentPeriod;
  }
  for (const rosterStats of rosterStatsTable) {
    const { throwAttempts, completions, yardsReceived, yardsThrown, goals, assists, blocks, throwaways, stalls, drops, oPointsPlayed, dPointsPlayed } = rosterStats;
    rosterStats.completionPercentage = throwAttempts === 0 ? 0 : Math.floor(completions / throwAttempts * 100);
    rosterStats.yardsReceived = Math.floor(yardsReceived);
    rosterStats.yardsThrown = Math.floor(yardsThrown);
    rosterStats.yardsTotal = rosterStats.yardsReceived + rosterStats.yardsThrown;
    rosterStats.plusMinus = goals + assists + blocks - throwaways - stalls - drops;
    rosterStats.pointsPlayed = oPointsPlayed + dPointsPlayed;
  }
  return { gameInfo, rosterLookup, rosterStatsTable, eventsTableHome, eventsTableAway, quartersHome, quartersAway, possessionsHome, possessionsAway, tsgHome, tsgAway };
};

const GameStats = () => {
  const [data, setData] = useState();
  const [results, setResults] = useState({});
  const [ignore, setIgnore] = useState(false);
  const [loadMoment, setLoadMoment] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [autoRefreshInterval, setAutoRefreshInterval] = useState(null);
  const [filterTeamsSummary, setFilterTeamsSummary] = useState(0);
  const [filterTeamsDetails, setFilterTeamsDetails] = useState(0);
  const [filterTypes, setFilterTypes] = useState(createFullFilter(eft.DEFINITION));
  const [filterLines, setFilterLines] = useState(createFullFilter(pft.DEFINITION));
  const [filterRosters, setFilterRosters] = useState([]);
  const [filterPeriods, setFilterPeriods] = useState([]);
  const pathNameSplit = window.location.pathname.split('/');

  const load = async (force) => {
    if (loading || (data && !force)) { return; }
    setLoading(true);
    let foundGame;
    let extGameID = pathNameSplit[pathNameSplit.length - 1];
    for (const part of pathNameSplit) {
      if (foundGame) { extGameID = part; }
      if (part === 'game') { foundGame = true; }
    }
    const gameData = await urlRequest('get', URL + extGameID);
    const output = formatData(gameData);
    const { rosterLookup, gameInfo, eventsTableHome, eventsTableAway, skip } = output;
    if (skip) {
      setIgnore(true);
      setLoadMoment(moment());
      setLoading(false);
      setLoaded(true);
      return;
    }
    if (!loaded) {
      if (rosterLookup) { setFilterRosters(createFullFilter(rosterLookup)); }
      const baseFilterPeriods = [];
      if (eventsTableHome && eventsTableHome.length) {
        baseFilterPeriods.push(eventsTableHome[eventsTableHome.length - 1].period);
        setFilterTeamsDetails(gameInfo.tsgIdHome);
      }
      if (eventsTableAway && eventsTableAway.length) {
        const period = eventsTableAway[eventsTableAway.length - 1].period;
        if (baseFilterPeriods.length === 0 || period !== baseFilterPeriods[0]) {
          baseFilterPeriods.push(period);
        }
        if (filterTeamsDetails === 0) {
          setFilterTeamsDetails(gameInfo.tsgIdAway);
        }
      }
      setFilterPeriods(baseFilterPeriods);
    }
    setData(gameData);
    setResults(output);
    setLoadMoment(moment());
    setLoading(false);
    setLoaded(true);
  };

  useEffect(() => {
    load();
  });

  const filterRosterStatsTable = (rosterStatsTable) => {
    return rosterStatsTable.filter((roster) => {
      if (filterTeamsSummary && filterTeamsSummary !== roster.tsgId) { return false; }
      if (IS_PRODUCTION && roster.name === 'Unknown') { return false; }
      return true;
    });
  };

  const createFilterOptionsAndLookup = () => {
    return [[{ label: 'Select all', value: -1 }, { label: 'Deselect all', value: -2 }], {}];
  };

  const populateFilterOptions = (options, lookup, value, definition) => {
    if (!lookup[value] && definition[value]) {
      const filterOption = { label: definition[value].label, value, count: 0 };
      lookup[value] = filterOption;
      options.push(filterOption);
    }
  };

  const toggleAutoRefresh = () => {
    if (autoRefreshInterval) {
      clearInterval(autoRefreshInterval);
      setAutoRefreshInterval(null);
    } else {
      setAutoRefreshInterval(setInterval(load, 10000));
    }
  };

  const filterEventsTable = (eventsTable, rosterLookup) => {
    const filteredEventsTable = [];
    let xTotal = 0;
    const xPoints = [];

    const [rosterFilterOptions, rosterFilterLookup] = createFilterOptionsAndLookup();
    const [typeFilterOptions, typeFilterLookup] = createFilterOptionsAndLookup();
    const [lineFilterOptions, lineFilterLookup] = createFilterOptionsAndLookup();
    const [periodFilterOptions, periodFilterLookup] = createFilterOptionsAndLookup();
    for (const event of eventsTable) {
      const { type, line, rosterIdThrower, rosterIdReceiver, period, receiverX } = event;
      const rosterIdThrowerUpdated = rosterIdThrower < 0 ? 0 : rosterIdThrower;
      const rosterIdReceiverUpdated = rosterIdReceiver < 0 ? 0 : rosterIdReceiver;
      const { throwerFilter, receiverFilter, receiverPos } = edt.DEFINITION[type];
      populateFilterOptions(rosterFilterOptions, rosterFilterLookup, rosterIdThrowerUpdated, rosterLookup);
      populateFilterOptions(rosterFilterOptions, rosterFilterLookup, rosterIdReceiverUpdated, rosterLookup);
      populateFilterOptions(typeFilterOptions, typeFilterLookup, throwerFilter, eft.DEFINITION);
      populateFilterOptions(typeFilterOptions, typeFilterLookup, receiverFilter, eft.DEFINITION);
      populateFilterOptions(lineFilterOptions, lineFilterLookup, line, lft.DEFINITION);
      populateFilterOptions(periodFilterOptions, periodFilterLookup, period, pft.DEFINITION);
      const matchRosterThrower = throwerFilter && filterRosters.includes(rosterIdThrowerUpdated);
      const matchTypeThrower = throwerFilter && filterTypes.includes(throwerFilter);
      const matchRosterReceiver = receiverFilter && filterRosters.includes(rosterIdReceiverUpdated);
      const matchTypeReceiver = receiverFilter && filterTypes.includes(receiverFilter);
      const matchLine = filterLines.includes(line);
      const matchPeriod = filterPeriods.includes(period);

      if (((matchRosterThrower && matchTypeThrower) || (matchRosterReceiver && matchTypeReceiver)) && matchLine && matchPeriod) {
        filteredEventsTable.push(event);
        if (receiverPos) {
          const xNormalized = receiverX * 3 / 160 + 0.5;
          xTotal += xNormalized;
          xPoints.push(xNormalized);
        }
      }
      const checkRosterThrower = !filterRosters.length || matchRosterThrower;
      const checkTypeThrower = !filterTypes.length || matchTypeThrower;
      const checkRosterReceiver = !filterRosters.length || matchRosterReceiver;
      const checkTypeReceiver = !filterTypes.length || matchTypeReceiver;
      const checkLine = !filterLines.length || matchLine;
      const checkPeriod = !filterPeriods.length || matchPeriod;
      if (checkLine && periodFilterLookup[period]) {
        if (checkRosterThrower && checkTypeThrower) { periodFilterLookup[period].count++; }
        if (checkRosterReceiver && checkTypeReceiver) { periodFilterLookup[period].count++; }
      }
      if (checkPeriod && lineFilterLookup[line]) {
        if (checkRosterThrower && checkTypeThrower) { lineFilterLookup[line].count++; }
        if (checkRosterReceiver && checkTypeReceiver) { lineFilterLookup[line].count++; }
      }
      if (checkLine && checkPeriod) {
        if (checkRosterThrower && typeFilterLookup[throwerFilter]) { typeFilterLookup[throwerFilter].count++; }
        if (checkRosterReceiver && typeFilterLookup[receiverFilter]) { typeFilterLookup[receiverFilter].count++; }
        if (checkTypeThrower && rosterFilterLookup[rosterIdThrowerUpdated]) { rosterFilterLookup[rosterIdThrowerUpdated].count++; }
        if (checkTypeReceiver && rosterFilterLookup[rosterIdReceiverUpdated]) { rosterFilterLookup[rosterIdReceiverUpdated].count++; }
      }
    }
    let xMean;
    let xGradientValues = '';
    if (xPoints.length > 2) {
      xMean = xTotal / xPoints.length;
      let xVariance = 0;
      for (const x of xPoints) {
        xVariance += Math.pow(x - xMean, 2) / xPoints.length;
      }
      if (xMean && xVariance) {
        const points = [];
        let max = 0;
        for (let i = 0; i <= 1; i += 1 / HEAT_MAP_DIVISIONS) {
          const value = Math.floor(100 / Math.pow(2 * Math.PI * xVariance, 0.5) * Math.pow(Math.E, -Math.pow(i - xMean, 2) / (2 * xVariance)));
          points.push(value);
          if (value > max) { max = value; }
        }
        if (max) {
          for (const value of points) {
            xGradientValues += (xGradientValues ? ' ' : '') + String(Math.floor(100 * value / max) / 100);
          }
        }
      }
    }

    return { filteredEventsTable: filteredEventsTable.sort((a, b) => a.type - b.type), rosterFilterOptions, typeFilterOptions, lineFilterOptions, periodFilterOptions, xMean, xGradientValues };
  };

  const toggleTeamFilterSummary = (value) => {
    setFilterTeamsSummary(value);
    if (filterTeamsSummary === filterTeamsDetails && value !== 0) {
      setFilterTeamsDetails(value);
    }
  };

  const toggleTeamFilterDetails = (value) => {
    setFilterTeamsDetails(value);
    if (filterTeamsSummary === filterTeamsDetails) {
      setFilterTeamsSummary(value);
    }
  };

  const toggleFilterArray = (currentValue, setFunction, value, definition) => {
    if (value === -2) {
      setFunction([]);
    } else if (value === -1) {
      setFunction(createFullFilter(definition));
    } else {
      const filters = Object.assign([], currentValue);
      const index = filters.indexOf(value);
      if (index !== -1) {
        filters.splice(index, 1);
      } else { filters.push(value); }
      setFunction(filters);
    }
  };

  const toggleRosterFilter = (_, { value }) => {
    const { rosterLookup } = results;
    toggleFilterArray(filterRosters, setFilterRosters, value, rosterLookup);
  };

  const toggleTypeFilter = (_, { value }) => {
    toggleFilterArray(filterTypes, setFilterTypes, value, eft.DEFINITION);
  };

  const toggleLineFilter = (_, { value }) => {
    toggleFilterArray(filterLines, setFilterLines, value, lft.DEFINITION);
  };

  const togglePeriodFilter = (_, { value }) => {
    toggleFilterArray(filterPeriods, setFilterPeriods, value, pft.DEFINITION);
  };

  if (!loaded) {
    return <Loader active>Loading</Loader>;
  }

  if (data.error) {
    return <Container style={{ marginTop: '10px' }}>
      {'Error loading data. ' + data.errorMessage}
    </Container>;
  }

  let errorMessage;
  if (ignore) {
    errorMessage = 'No advanced game stats have been recorded yet.';
  } else if (!data.game) {
    errorMessage = 'Error loading any gamedata.';
  } else if (!Array.isArray(data.rostersHome) || !Array.isArray(data.rostersAway)) {
    errorMessage = 'Error loading player information.';
  } else if (!data.tsgAway && !data.tsgHome) {
    errorMessage = 'No advanced game stats have been recorded yet.';
  }
  if (errorMessage) {
    return (
      <Container className="game-stats">
        <Row>
          <h2>Advanced stats</h2>
        </Row>
        <Row>
          <Col>
            <div className="error-container">
              {errorMessage}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  const { tsgHome, tsgAway } = data;
  const { eventsTableAway, eventsTableHome, gameInfo, rosterLookup, rosterStatsTable, quartersHome, quartersAway } = results;
  const isHome = gameInfo.tsgIdHome === filterTeamsDetails;
  const eventsTable = isHome ? eventsTableHome : eventsTableAway;
  const quarters = isHome ? quartersHome : quartersAway;
  const filteredRosterStatsTable = filterRosterStatsTable(rosterStatsTable);
  const { filteredEventsTable, rosterFilterOptions, typeFilterOptions, lineFilterOptions, periodFilterOptions, xMean, xGradientValues } = filterEventsTable(eventsTable, rosterLookup, filterRosters, filterTypes, filterLines, filterPeriods);
  const fieldMapHeight = 450;
  const fieldMapWidth = fieldMapHeight / 2.25;
  return (
    <Container className="game-stats">
      <Row><h2>Box Scores</h2></Row>
      <QuarterBreakdown gameInfo={gameInfo} />
      {SHOW_TEAM_STATS ? <TeamStatsTable gameInfo={gameInfo} tsgHome={tsgHome} tsgAway={tsgAway} /> : null}
      {SHOW_TABLE ? <div className="stats-table">
        <Row><h2>Player stats</h2></Row>
        <InfoHeader
          showEither
          gameInfo={gameInfo}
          filterTeams={filterTeamsSummary}
          toggleTeamFilter={toggleTeamFilterSummary}
          refreshing={loading}
          autoRefresh={autoRefreshInterval}
          toggleAutoRefresh={toggleAutoRefresh}
          loadMoment={loadMoment}
          load={() => load(true)}
        />
        <StatsTable filteredRosterStatsTable={filteredRosterStatsTable} />
      </div> : null}
      {SHOW_FIELD_MAP ? <div className="field-map">
        <Row style={{ marginTop: '10px', marginBottom: '20px' }}><h2>Field map</h2></Row>
        <InfoHeader
          gameInfo={gameInfo}
          filterTeams={filterTeamsDetails}
          toggleTeamFilter={toggleTeamFilterDetails}
          refreshing={loading}
          autoRefresh={autoRefreshInterval}
          toggleAutoRefresh={toggleAutoRefresh}
          loadMoment={loadMoment}
          load={() => load(true)}
        />
        {!filterTeamsDetails || !eventsTable.length ? (
          <Row>
            {!eventsTable.length ? 'No player events to map yet.' : 'You must select a team to show field map'}
          </Row>
        ) : (
          <Row>
            <Col md={6} style={{ maxWidth: '350px' }}>
              <Row>
                <Col xs={6}>
                  <FieldMapFilter header="Select Player" options={rosterFilterOptions} values={filterRosters} onClick={toggleRosterFilter} />
                </Col>
                <Col xs={6}>
                  <FieldMapFilter header="Select Event" options={typeFilterOptions} values={filterTypes} onClick={toggleTypeFilter} sortByValue />
                  <FieldMapFilter header="Select Line" options={lineFilterOptions} values={filterLines} onClick={toggleLineFilter} sortByValue />
                  <FieldMapFilter header="Select Period" options={periodFilterOptions} values={filterPeriods} onClick={togglePeriodFilter} sortByValue />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <FieldMapEvents
                fieldMapHeight={fieldMapHeight}
                fieldMapWidth={fieldMapWidth}
                eventsTable={filteredEventsTable}
                filterRosters={filterRosters}
                filterTypes={filterTypes}
                rosterLookup={rosterLookup}
                xGradientValues={xGradientValues}
                xMean={xMean}
              />
            </Col>
          </Row>
        )}
      </div> : null}
      {SHOW_GRAPH ? <ScoresGraph gameInfo={gameInfo} /> : null}
      {SHOW_PLAY_BY_PLAY ? <div className="play-by-play">
        <Row><h2>Play by Play</h2></Row>
        <InfoHeader
          gameInfo={gameInfo}
          filterTeams={filterTeamsDetails}
          toggleTeamFilter={toggleTeamFilterDetails}
          refreshing={loading}
          autoRefresh={autoRefreshInterval}
          toggleAutoRefresh={toggleAutoRefresh}
          loadMoment={loadMoment}
          load={() => load(true)}
        />
        {!filterTeamsDetails || !quarters.length ? (
          <Row>
            {!quarters.length ? 'No play by play events to map yet.' : 'You must select a team to show play by play'}
          </Row>
        ) : <PlayByPlayTable quarters={quarters} rosterLookup={rosterLookup} />}
      </div> : null}
    </Container>
  );
};

export default GameStats;

function sleep(ms) {    // eslint-disable-line no-unused-vars
  return new Promise((resolve) => setTimeout(resolve, ms));
}
