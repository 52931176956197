import React from 'react';
import { Row } from 'react-bootstrap';

class GameStatsTeamStatsTable extends React.PureComponent {
  render() {
    const { gameInfo, tsgHome, tsgAway } = this.props;
    return (
      <div className="team-stats">
        <Row><h2>Team Stats</h2></Row>
        <Row>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{gameInfo.teamAway}</th>
                <th>{gameInfo.teamHome}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Completions</th>
                <td>{tsgAway.completionsDenom === 0 ? 'N/A' : Math.round(100 * tsgAway.completionsNumer / tsgAway.completionsDenom) + '% (' + tsgAway.completionsNumer + '/' + tsgAway.completionsDenom + ')'}</td>
                <td>{tsgHome.completionsDenom === 0 ? 'N/A' : Math.round(100 * tsgHome.completionsNumer / tsgHome.completionsDenom) + '% (' + tsgHome.completionsNumer + '/' + tsgHome.completionsDenom + ')'}</td>
              </tr>
              <tr>
                <th>Hucks</th>
                <td>{tsgAway.hucksDenom === 0 ? 'N/A' : Math.round(100 * tsgAway.hucksNumer / tsgAway.hucksDenom) + '% (' + tsgAway.hucksNumer + '/' + tsgAway.hucksDenom + ')'}</td>
                <td>{tsgHome.hucksDenom === 0 ? 'N/A' : Math.round(100 * tsgHome.hucksNumer / tsgHome.hucksDenom) + '% (' + tsgHome.hucksNumer + '/' + tsgHome.hucksDenom + ')'}</td>
              </tr>
              <tr>
                <th>Hold %</th>
                <td>{tsgAway.oLinePoints === 0 ? 'N/A' : Math.round(100 * tsgAway.oLineScores / tsgAway.oLinePoints) + '% (' + tsgAway.oLineScores + '/' + tsgAway.oLinePoints + ')'}</td>
                <td>{tsgHome.oLinePoints === 0 ? 'N/A' : Math.round(100 * tsgHome.oLineScores / tsgHome.oLinePoints) + '% (' + tsgHome.oLineScores + '/' + tsgHome.oLinePoints + ')'}</td>
              </tr>
              <tr>
                <th>O-Line Conversion %</th>
                <td>{tsgAway.oLinePossessions === 0 ? 'N/A' : Math.round(100 * tsgAway.oLineScores / tsgAway.oLinePossessions) + '% (' + tsgAway.oLineScores + '/' + tsgAway.oLinePossessions + ')'}</td>
                <td>{tsgHome.oLinePossessions === 0 ? 'N/A' : Math.round(100 * tsgHome.oLineScores / tsgHome.oLinePossessions) + '% (' + tsgHome.oLineScores + '/' + tsgHome.oLinePossessions + ')'}</td>
              </tr>
              <tr>
                <th>Break %</th>
                <td>{tsgAway.dLinePoints === 0 ? 'N/A' : Math.round(100 * tsgAway.dLineScores / tsgAway.dLinePoints) + '% (' + tsgAway.dLineScores + '/' + tsgAway.dLinePoints + ')'}</td>
                <td>{tsgHome.dLinePoints === 0 ? 'N/A' : Math.round(100 * tsgHome.dLineScores / tsgHome.dLinePoints) + '% (' + tsgHome.dLineScores + '/' + tsgHome.dLinePoints + ')'}</td>
              </tr>
              <tr>
                <th>D-Line Conversion %</th>
                <td>{tsgAway.dLinePossessions === 0 ? 'N/A' : Math.round(100 * tsgAway.dLineScores / tsgAway.dLinePossessions) + '% (' + tsgAway.dLineScores + '/' + tsgAway.dLinePossessions + ')'}</td>
                <td>{tsgHome.dLinePossessions === 0 ? 'N/A' : Math.round(100 * tsgHome.dLineScores / tsgHome.dLinePossessions) + '% (' + tsgHome.dLineScores + '/' + tsgHome.dLinePossessions + ')'}</td>
              </tr>
              <tr>
                <th>Red Zone Conversion %</th>
                <td>{tsgAway.redZonePossessions === 0 ? 'N/A' : Math.round(100 * tsgAway.redZoneScores / tsgAway.redZonePossessions) + '% (' + tsgAway.redZoneScores + '/' + tsgAway.redZonePossessions + ')'}</td>
                <td>{tsgHome.redZonePossessions === 0 ? 'N/A' : Math.round(100 * tsgHome.redZoneScores / tsgHome.redZonePossessions) + '% (' + tsgHome.redZoneScores + '/' + tsgHome.redZonePossessions + ')'}</td>
              </tr>
              <tr>
                <th>Blocks</th>
                <td>{tsgAway.blocks}</td>
                <td>{tsgHome.blocks}</td>
              </tr>
              <tr>
                <th>Turnovers</th>
                <td>{tsgAway.turnovers}</td>
                <td>{tsgHome.turnovers}</td>
              </tr>
            </tbody>
          </table>
        </Row>
      </div>
    );
  }
}

export default GameStatsTeamStatsTable;
