import React from 'react';
import { Popup } from 'semantic-ui-react';
import * as s from '../../constants/Styles';
import * as sd from '../../constants/SvgDimensions';
import * as edt from '../../constants/EventDisplayTypes';

class GameStatsDrawEvent extends React.PureComponent {
  render() {
    const { type, throwerX, throwerY, rosterIdThrower, receiverX, receiverY, rosterIdReceiver, fieldMapWidth, fieldMapHeight, fillThrower, fillReceiver, rosterLookup } = this.props;
    const x1 = sd.SVG_INDENT_X + throwerX * 3.75 + fieldMapWidth / 2;
    const x2 = sd.SVG_INDENT_X + receiverX * 3.75 + fieldMapWidth / 2;
    const y1 = sd.SVG_INDENT_Y + fieldMapHeight - throwerY * 3.75;
    const y2 = sd.SVG_INDENT_Y + fieldMapHeight - receiverY * 3.75;
    const { displayOnMap, throwerColor, receiverColor, lineColor, throwerSize, receiverSize, thrower, receiver, receiverPos, throwerPos, throwerLabel, receiverLabel } = edt.DEFINITION[type];
    if (!displayOnMap) { return null; }
    let throwerIcon;
    if (thrower && x1 && y1) {
      throwerIcon = <rect x={x1 - throwerSize} y={y1 - throwerSize} width={throwerSize * 2} height={throwerSize * 2} stroke={throwerColor} fill={fillThrower} />;
      if (type > 1 && rosterIdThrower && rosterLookup[rosterIdThrower]) {
        throwerIcon = <Popup content={rosterLookup[rosterIdThrower].label + ' - ' + throwerLabel} trigger={throwerIcon} inverted style={s.POP_UP_STYLE} hideOnScroll />;
      }
    }
    let receiverIcon;
    if (receiver) {
      receiverIcon = <circle cx={x2} cy={y2} r={receiverSize} stroke={receiverColor} fill={fillReceiver} />;
      if (type > 1 && rosterIdReceiver && rosterLookup[rosterIdReceiver]) {
        receiverIcon = <Popup content={rosterLookup[rosterIdReceiver].label + ' - ' + receiverLabel} trigger={receiverIcon} inverted style={s.POP_UP_STYLE} hideOnScroll />;
      }
    }
    return (
      <g>
        {receiverPos && throwerPos ? <line x1={x1} x2={x2} y1={y1} y2={y2} stroke={lineColor} /> : null}
        {throwerIcon}
        {receiverIcon}
      </g>
    );
  }
}

export default GameStatsDrawEvent;
