import React from 'react';
import { Row } from 'react-bootstrap';

const GRAPH_WIDTH = 500;
const GRAPH_HEIGHT = 500;
const LEGEND_HEIGHT = 80;
const LEGEND_WIDTH = 100;
const LEGEND_GAP = 20;
const Y_MARGIN = 20;
const X_MARGIN = 40;
const MIN_STEP = 1;

const GRID_COLOR = 'grey';
const HOME_COLOR = 'green';
const AWAY_COLOR = 'red';

class GameStatsScoresGraph extends React.PureComponent {
  render() {
    const { gameInfo } = this.props;
    if (!gameInfo.scoreTimesAway || !gameInfo.scoreTimesHome || (gameInfo.scoreTimesAway.length < 2 && gameInfo.scoreTimesHome.length < 2)) {
      return null;
    }
    const maxYValue = Math.max(gameInfo.scoreTimesAway.length, gameInfo.scoreTimesHome.length);
    const maxXValue = Math.max(gameInfo.scoreTimesAway[gameInfo.scoreTimesAway.length - 1], gameInfo.scoreTimesHome[gameInfo.scoreTimesHome.length - 1]) + 50;
    const numberOfYAxisValues = (Math.floor(maxYValue / 10) + 1);
    const maxScoreValue = numberOfYAxisValues * 10;
    const heightPerScore = GRAPH_HEIGHT / maxScoreValue;
    const graphBottom = Y_MARGIN + GRAPH_HEIGHT;
    const legendX = (GRAPH_WIDTH - (LEGEND_WIDTH * 2 + LEGEND_GAP)) / 2;
    return (
      <div className="scores-graph">
        <Row>
          <svg height={GRAPH_HEIGHT + LEGEND_HEIGHT} width={GRAPH_WIDTH + X_MARGIN}>
            <line
              x1={X_MARGIN}
              y1={Y_MARGIN}
              x2={X_MARGIN}
              y2={graphBottom}
              stroke={GRID_COLOR}
              strokeWidth="1"
            />
            {[...Array(numberOfYAxisValues + 1)].map((x, i) => {
              const value = i * 10;
              const axisValueHeight = graphBottom - (heightPerScore * value);
              return (
                <g key={value}>
                  {numberOfYAxisValues !== i ?
                    <line
                      x1={X_MARGIN}
                      y1={axisValueHeight}
                      x2={GRAPH_WIDTH + X_MARGIN}
                      y2={axisValueHeight}
                      stroke={GRID_COLOR}
                      strokeWidth="1"
                    /> : null
                  }
                  <text
                    x={X_MARGIN - 5}
                    y={axisValueHeight}
                    stroke={GRID_COLOR}
                    alignmentBaseline="middle"
                    textAnchor="end"
                    fontSize="14"
                  >
                    {value}
                  </text>
                </g>
              );
            })}
            {[gameInfo.scoreTimesAway, gameInfo.scoreTimesHome].map((events, awayHomeIndex) => {
              const color = awayHomeIndex === 0 ? AWAY_COLOR : HOME_COLOR;
              let prevX;
              let prevY;
              return (
                <g key={awayHomeIndex}>
                  {events.map((event, score) => {
                    let svgLine;
                    let x = Math.floor(X_MARGIN + (event / maxXValue * GRAPH_WIDTH));
                    const y = Math.floor(graphBottom - (heightPerScore * score));
                    if (prevX && prevY) {
                      if (x <= (prevX + MIN_STEP)) { x = prevX + MIN_STEP; }
                      svgLine = (
                        <line
                          key={score}
                          x1={prevX}
                          y1={prevY}
                          x2={x}
                          y2={y}
                          stroke={color}
                          strokeWidth="2"
                        />
                      );
                    }
                    prevX = x;
                    prevY = y;
                    return svgLine;
                  })}
                </g>
              );
            })}
            <rect
              x={legendX + 5}
              y={GRAPH_HEIGHT + Y_MARGIN + (LEGEND_HEIGHT / 2) - 10}
              height="20"
              width="20"
              stroke={AWAY_COLOR}
              fill={AWAY_COLOR}
            />
            <text
              x={legendX + 30}
              y={GRAPH_HEIGHT + Y_MARGIN + (LEGEND_HEIGHT / 2)}
              stroke="black"
              alignmentBaseline="middle"
              fontSize="14"
            >
              {gameInfo.teamAbbrevAway}
            </text>
            <rect
              x={legendX + LEGEND_WIDTH + LEGEND_GAP + 5}
              y={GRAPH_HEIGHT + Y_MARGIN + (LEGEND_HEIGHT / 2) - 10}
              height="20"
              width="20"
              stroke={HOME_COLOR}
              fill={HOME_COLOR}
            />
            <text
              x={legendX + LEGEND_WIDTH + LEGEND_GAP + 30}
              y={GRAPH_HEIGHT + Y_MARGIN + (LEGEND_HEIGHT / 2)}
              stroke="black"
              alignmentBaseline="middle"
              fontSize="14"
            >
              {gameInfo.teamAbbrevHome}
            </text>
          </svg>
        </Row>
      </div>
    );
    /*
    if (!height || !width || !eventsAway || !eventsHome || !eventsAway || (eventsAway.length < 2 && eventsHome.length < 2)) {
      return null;
    }
    const graphBottom = Y_MARGIN + graphHeight;

    return (
      <Svg height={height} width={width}>
        <Line
          x1={X_MARGIN}
          y1={Y_MARGIN}
          x2={X_MARGIN}
          y2={graphBottom}
          stroke={GRID_COLOR}
          strokeWidth="1"
        />
        {[...Array(numberOfYAxisValues + 1)].map((x, i) => {
          const value = i * 10;
          const axisValueHeight = graphBottom - (heightPerScore * value);
          return (
            <G key={value}>
              {numberOfYAxisValues !== i ?
                <Line
                  x1={X_MARGIN}
                  y1={axisValueHeight}
                  x2={width}
                  y2={axisValueHeight}
                  stroke={GRID_COLOR}
                  strokeWidth="1"
                /> : null
              }
              <Text
                x={X_MARGIN - 5}
                y={axisValueHeight}
                stroke={GRID_COLOR}
                alignmentBaseline="middle"
                textAnchor="end"
                fontSize="14"
              >
                {value}
              </Text>
            </G>
          );
        })}
        {[eventsAway, eventsHome].map((events, awayHomeIndex) => {
          const color = awayHomeIndex === 0 ? AWAY_COLOR : HOME_COLOR;
          let prevX;
          let prevY;
          return (
            <G key={awayHomeIndex}>
              {events.map((event, index) => {
                let svgLine;
                let x = Math.floor(X_MARGIN + event.timePercent * graphWidth);
                const y = Math.floor(graphBottom - (heightPerScore * event.score));
                if (prevX && prevY) {
                  if (x <= (prevX + MIN_STEP)) { x = prevX + MIN_STEP; }
                  svgLine = (
                    <Line
                      key={index}
                      x1={prevX}
                      y1={prevY}
                      x2={x}
                      y2={y}
                      stroke={color}
                      strokeWidth="1"
                    />
                  );
                }
                prevX = x;
                prevY = y;
                return svgLine;
              })}
            </G>
          );
        })}
        <Rect
          x={legendX + 5}
          y={legendHeight - 10}
          height="20"
          width="20"
          stroke={AWAY_COLOR}
          fill={AWAY_COLOR}
        />
        <Text
          x={legendX + 30}
          y={legendHeight}
          stroke="black"
          alignmentBaseline="middle"
          fontSize="14"
        >
          {teamAbbrevAway}
        </Text>
        <Rect
          x={legendX + LEGEND_WIDTH + LEGEND_GAP + 5}
          y={legendHeight - 10}
          height="20"
          width="20"
          stroke={HOME_COLOR}
          fill={HOME_COLOR}
        />
        <Text
          x={legendX + LEGEND_WIDTH + LEGEND_GAP + 30}
          y={legendHeight}
          stroke="black"
          alignmentBaseline="middle"
          fontSize="14"
        >
          {teamAbbrevHome}
        </Text>
      </Svg>
    );
    */
  }
}

export default GameStatsScoresGraph;
