// Defense (11)
export const SET_D_LINE = 2;
export const SET_D_LINE_NO_PULL = 40;
export const PULL_INBOUNDS = 3;
export const PULL_OUT_OF_BOUNDS = 4;
export const PULL_OUR_OFFSIDES = 44;
export const PULL_THEIR_OFFSIDES = 45;
export const BLOCK = 5;
export const THROWAWAY_CAUSED = 9;
export const CALLAHAN = 6;
export const SCORED_ON = 21;
export const STALL_CAUSED = 18;
export const D_PENALTY_ON_US = 11;
export const O_PENALTY_ON_THEM = 13;
export const THEIR_MIDPOINT_TIMEOUT = 15;
export const THEIR_TIMEOUT_ON_O = 31;
export const OUR_TIMEOUT_ON_D = 32;

// Offense (10)
export const SET_O_LINE = 1;
export const SET_O_LINE_NO_PULL = 41;
export const DROP = 19;
export const POSSESSION = 20;
export const CALLAHAN_THROWN = 7;
export const THROWAWAY = 8;
export const GOAL = 22;
export const STALL = 17;
export const O_PENALTY_ON_US = 10;
export const D_PENALTY_ON_THEM = 12;
export const OUR_MIDPOINT_TIMEOUT = 14;
export const THEIR_TIMEOUT_ON_D = 29;
export const OUR_TIMEOUT_ON_O = 30;

// Any (9)
export const UNKNOWN = 0;
export const INJURY_ON_O = 42;    // XBDN Split into O vs D
export const INJURY_ON_D = 43;    // XBDN Split into O vs D
export const END_OF_Q1 = 23;
export const HALFTIME = 24;
export const END_OF_Q3 = 25;
export const GAME_OVER = 26;
export const END_OF_OT1 = 27;
export const END_OF_OT2 = 28;
