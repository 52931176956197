
export const THROW = 1;
export const CATCH = 2;
export const ASSIST = 3;
export const GOAL = 4;
export const THROWAWAY = 5;
export const DROP = 6;
export const STALL = 7;
export const CALLAHAN_THROWN = 8;

export const DEFINITION = {
  [THROW]: { label: 'Throws' },
  [CATCH]: { label: 'Catches' },
  [ASSIST]: { label: 'Assists' },
  [GOAL]: { label: 'Goals' },
  [THROWAWAY]: { label: 'Throwaways' },
  [DROP]: { label: 'Drops' },
  [STALL]: { label: 'Stalls' },
  [CALLAHAN_THROWN]: { label: 'Callahans Thrown' },
};
