export const SVG_LEGEND_INDENT_X = 10;
export const SVG_LEGEND_X_GAP = 10;
export const SVG_LEGEND_Y = 20;
export const SVG_LEGEND_Y_GAP = 30;
export const SVG_LEGEND_R = 6;
export const SVG_LEGEND_LINE_LENGTH = 24;
export const SVG_LEGEND_LINE_WIDTH = 2;
export const SVG_INDENT_X = 5;
export const SVG_INDENT_Y = 5;
export const MARKER_SIZE_NORMAL = 3;
export const MARKER_SIZE_SPECIAL = 3;
