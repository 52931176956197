import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import GameStats from './components/GameStats';

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table/react-table.css';
import './index.css';  // Keep here so that css gets packaged with other static files

const App = () => {
  return (
    <>
      <GameStats />
      <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} />
    </>
  );
};

createRoot(document.getElementById('stats-pages')).render(<App />);
