import axios from 'axios';
import { toast } from 'react-toastify';

export default async function urlRequest(method, url, payload) {
  if (!['get', 'post', 'patch', 'delete', 'put'].includes(method)) {
    console.warn('Invalid method', method);
    return;
  }
  try {
    const response = await axios[method](url, payload);
    return response.data;
  } catch (err) {
    let errorMessage = 'Unknown error';
    if (err.response) {
      if (err.response.data && err.response.data.errorMessage) {
        errorMessage = err.response.data.errorMessage;
      } else if (err.response.request && err.response.request.status) {
        errorMessage = err.response.request.status + ' Response Code';
      }
    }
    if (method !== 'get') { toast(errorMessage, { type: toast.TYPE.ERROR }); }
    return { error: true, errorMessage };
  }
}

function sleep(ms) {    // eslint-disable-line no-unused-vars
  return new Promise((resolve) => setTimeout(resolve, ms));
}
