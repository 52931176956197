import React from 'react';
import { Popup } from 'semantic-ui-react';
import * as s from '../../constants/Styles';
import * as sd from '../../constants/SvgDimensions';

class GameStatsDrawEvents extends React.PureComponent {
  render() {
    const { fieldMapHeight, fieldMapWidth, xGradientValues, xMean } = this.props;
    return (
      <g>
        <defs>
          <filter id="bellTransform" colorInterpolationFilters="sRGB">
            <feComponentTransfer>
              <feFuncR type="table" tableValues={xGradientValues}/>
            </feComponentTransfer>
          </filter>
        </defs>
        <linearGradient id="redgradient" x1="0%" x2="100%" y1="0%" y2="0%">
          <stop offset="0%" stopColor="#000000"/>
          <stop offset="100%" stopColor="#ff0000"/>
        </linearGradient>
        <rect
          x={sd.SVG_INDENT_X}
          y={sd.SVG_INDENT_Y + fieldMapHeight + 5}
          height={10}
          width={fieldMapWidth}
          filter="url(#bellTransform)"
          fill="url(#redgradient)"
        />
        <line
          x1={sd.SVG_INDENT_X + fieldMapWidth * xMean}
          x2={sd.SVG_INDENT_X + fieldMapWidth * xMean}
          y1={sd.SVG_INDENT_Y + fieldMapHeight + 5}
          y2={sd.SVG_INDENT_Y + fieldMapHeight + 20}
          stroke="black"
          strokeWidth={3}
          style={{ opacity: 0.5 }}
        />
        <Popup
          content={'On average, events occurred ' + Math.round(Math.abs((xMean - 0.5) * 160 / 3)) + ' yards toward the ' + (xMean < 0.5 ? 'backhand' : 'forehand') + ' side.'}
          trigger={
            <text
              x={sd.SVG_INDENT_X + fieldMapWidth * xMean}
              y={sd.SVG_INDENT_Y + fieldMapHeight + 20}
              textAnchor={xMean < 0.2 ? 'left' : 'middle'}
              alignmentBaseline="hanging"
            >
              {Math.round(Math.abs((xMean - 0.5) * 160 / 3))} {xMean < 0.5 ? 'B' : 'F'}
            </text>
          }
          inverted
          style={s.POP_UP_STYLE}
          hideOnScroll
        />
      </g>
    );
  }
}

export default GameStatsDrawEvents;
