import * as c from './Colors';
import * as sd from './SvgDimensions';
import * as eft from './EventFilterTypes';

export const PASS = 1;
export const DROPPED_PASS = 2;
export const THROWAWAY = 3;
export const STALL = 4;
export const CALLAHAN_THROWN = 5;
export const SCORE = 6;
export const PULL_INBOUNDS = 10;
export const PULL_OUT_OF_BOUNDS = 11;
export const PULL_OUR_OFFSIDES = 12;
export const PULL_THEIR_OFFSIDES = 13;
export const BLOCK = 20;
export const CALLAHAN = 21;
export const STALL_CAUSED = 22;
export const THROWAWAY_CAUSED = 23;
export const THEIR_GOAL = 24;
export const TIMEOUT = 30;
export const INJURY = 31;
export const UNKNOWN_SUB = 32;

export const DEFINITION = {
  [PASS]: {
    label: 'Pass',
    displayOnMap: true,
    thrower: true,
    throwerFilter: eft.THROW,
    throwerLabel: 'Throw',
    throwerPos: true,
    receiver: true,
    receiverFilter: eft.CATCH,
    receiverLabel: 'Catch',
    receiverPos: true,
    throwerSize: sd.MARKER_SIZE_NORMAL,
    receiverSize: sd.MARKER_SIZE_NORMAL,
    throwerColor: c.COLOR_PASS,
    lineColor: c.COLOR_PASS,
    receiverColor: c.COLOR_PASS,
  },
  [DROPPED_PASS]: {
    label: 'Drop',
    displayOnMap: true,
    thrower: true,
    throwerFilter: eft.THROW,
    throwerLabel: 'Threw to a drop',
    throwerPos: true,
    receiver: true,
    receiverFilter: eft.DROP,
    receiverLabel: 'Dropped',
    receiverPos: true,
    throwerSize: sd.MARKER_SIZE_NORMAL,
    receiverSize: sd.MARKER_SIZE_SPECIAL,
    throwerColor: c.COLOR_PASS,
    lineColor: c.COLOR_THROWAWAY,
    receiverColor: c.COLOR_THROWAWAY,
  },
  [THROWAWAY]: {
    label: 'Throwaway',
    displayOnMap: true,
    thrower: true,
    throwerFilter: eft.THROWAWAY,
    throwerLabel: 'Throwaway',
    throwerPos: true,
    receiverPos: true,
    throwerSize: sd.MARKER_SIZE_SPECIAL,
    throwerColor: c.COLOR_THROWAWAY,
    lineColor: c.COLOR_THROWAWAY,
  },
  [STALL]: {
    label: 'Stall',
    displayOnMap: true,
    thrower: true,
    throwerFilter: eft.STALL,
    throwerLabel: 'Stalled',
    throwerPos: true,
    throwerSize: sd.MARKER_SIZE_SPECIAL,
    throwerColor: c.COLOR_STALL,
    lineColor: c.COLOR_STALL,
  },
  [CALLAHAN_THROWN]: {
    label: 'Callahan Thrown',
    displayOnMap: true,
    thrower: true,
    throwerFilter: eft.CALLAHAN_THROWN,
    throwerLabel: 'Callahan Thrown',
    throwerPos: true,
    receiverPos: true,
    throwerSize: sd.MARKER_SIZE_SPECIAL,
    throwerColor: c.COLOR_CALLAHAN_THROWN,
    lineColor: c.COLOR_CALLAHAN_THROWN,
  },
  [SCORE]: {
    label: 'Score',
    displayOnMap: true,
    thrower: true,
    throwerFilter: eft.ASSIST,
    throwerLabel: 'Assist',
    throwerPos: true,
    receiver: true,
    receiverFilter: eft.GOAL,
    receiverLabel: 'Goal',
    receiverPos: true,
    throwerSize: sd.MARKER_SIZE_SPECIAL,
    receiverSize: sd.MARKER_SIZE_SPECIAL,
    throwerColor: c.COLOR_GOAL,
    lineColor: c.COLOR_GOAL,
    receiverColor: c.COLOR_GOAL,
  },
  [PULL_INBOUNDS]: {
    label: 'Pull',
    displayOnMap: false,
    receiver: true,
    receiverPos: true,
  },
  [PULL_OUT_OF_BOUNDS]: {
    label: 'OB Pull',
    displayOnMap: false,
    receiver: true,
    receiverPos: true,
  },
  [PULL_OUR_OFFSIDES]: {
    label: 'Pull. We were offsides',
    displayOnMap: false,
    receiver: true,
  },
  [PULL_THEIR_OFFSIDES]: {
    label: 'Pull. They were offsides',
    displayOnMap: false,
    receiver: true,
  },
  [BLOCK]: {
    label: 'Block',
    displayOnMap: false,
    receiver: true,
  },
  [CALLAHAN]: {
    label: 'Callahan',
    displayOnMap: false,
  },
  [STALL_CAUSED]: {
    label: 'Stall caused',
    displayOnMap: false,
  },
  [THROWAWAY_CAUSED]: {
    label: 'Throwaway caused',
    displayOnMap: false,
  },
  [THEIR_GOAL]: {
    label: 'They scored',
    displayOnMap: false,
  },
  [TIMEOUT]: {
    label: 'Timeout',
    displayOnMap: false,
  },
  [INJURY]: {
    label: 'Injury',
    displayOnMap: false,
  },
  [UNKNOWN_SUB]: {
    label: 'Unknown sub',
    displayOnMap: false,
  },
};
