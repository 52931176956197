import React from 'react';
import DrawEvent from './FieldMapEvent';
import FieldMapLegend from './FieldMapLegend';
import FieldMapHorizontalGraph from './FieldMapHorizontalGraph';
import * as sd from '../../constants/SvgDimensions';
import * as c from '../../constants/Colors';
import * as edt from '../../constants/EventDisplayTypes';

class GameStatsDrawEvents extends React.PureComponent {
  render() {
    const { fieldMapHeight, fieldMapWidth, eventsTable, filterRosters, filterTypes, rosterLookup, xGradientValues, xMean, hideLegend } = this.props;
    if (!eventsTable) { return null; }
    return (
      <svg height={fieldMapHeight + 50 + sd.SVG_INDENT_Y} width={fieldMapWidth + (hideLegend ? sd.SVG_INDENT_X : 200) + sd.SVG_INDENT_X} style={{ position: 'relative' }}>
        <rect x={sd.SVG_INDENT_X} y={sd.SVG_INDENT_Y} height={fieldMapHeight} width={fieldMapWidth} stroke="grey" fill={c.COLOR_FIELD} />
        <line x1={sd.SVG_INDENT_X} x2={sd.SVG_INDENT_X + fieldMapWidth} y1={fieldMapHeight / 6 + sd.SVG_INDENT_Y} y2={fieldMapHeight / 6 + sd.SVG_INDENT_Y} stroke="lightgrey" />
        <line x1={sd.SVG_INDENT_X} x2={sd.SVG_INDENT_X + fieldMapWidth} y1={fieldMapHeight * 5 / 6 + sd.SVG_INDENT_Y} y2={fieldMapHeight * 5 / 6 + sd.SVG_INDENT_Y} stroke="lightgrey" />
        {eventsTable && eventsTable.length ? eventsTable.map((event) => {
          const { id, type, throwerX, throwerY, receiverX, receiverY, rosterIdThrower, rosterIdReceiver } = event;
          const { throwerColor, receiverColor, throwerFilter, receiverFilter, displayOnMap } = edt.DEFINITION[type];
          if (!displayOnMap) { return null; }
          return <DrawEvent key={id}
            type={type}
            throwerX={throwerX}
            throwerY={throwerY}
            receiverX={receiverX}
            receiverY={receiverY}
            rosterIdThrower={rosterIdThrower}
            rosterIdReceiver={rosterIdReceiver}
            fieldMapWidth={fieldMapWidth}
            fieldMapHeight={fieldMapHeight}
            fillThrower={(!filterRosters || filterRosters.includes(rosterIdThrower)) && (!filterTypes || filterTypes.includes(throwerFilter)) ? throwerColor : c.COLOR_FIELD}
            fillReceiver={(!filterRosters || filterRosters.includes(rosterIdReceiver)) && (!filterTypes || filterTypes.includes(receiverFilter)) ? receiverColor : c.COLOR_FIELD}
            rosterLookup={rosterLookup}
          />;
        }) : <text x={sd.SVG_INDENT_X + 100} y={sd.SVG_INDENT_Y + 37.5} alignmentBaseline="central" textAnchor="middle">No events match your filter</text>}
        {!hideLegend ? <FieldMapLegend fieldMapWidth={fieldMapWidth} /> : null}
        {xGradientValues !== undefined && xMean !== undefined ? <FieldMapHorizontalGraph
          fieldMapHeight={fieldMapHeight}
          fieldMapWidth={fieldMapWidth}
          xGradientValues={xGradientValues}
          xMean={xMean}
        /> : null}
      </svg>
    );
  }
}

export default GameStatsDrawEvents;
